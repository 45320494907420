import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../photos/Logo.png';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <header className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <img src={logo} alt="Company Logo" className="h-8" />
            </Link>
            
            <div className="sm:hidden">
              <button
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                type="button"
                className="text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-900"
                aria-label="Toggle mobile menu"
              >
                {/* Hamburger Icon */}
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-4 6h4" />
                </svg>
              </button>
            </div>
            <div className="hidden sm:flex space-x-8">
              {/* Desktop Nav Links */}
              <Link to="/About" className="text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium">About</Link>
              <Link to="/Services" className="text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium">Services</Link>
              <Link to="/Contact" className="text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium">Contact</Link>
              <Link to="/Login" className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-full text-sm font-medium shadow-sm">Login</Link>
            </div>
          </div>
        </div>
        
        {/* Mobile Menu */}
        <div className={`sm:hidden ${isMobileMenuOpen ? 'block' : 'hidden'} absolute w-full bg-white border-t border-gray-200`}>
          <Link to="/About" className="block text-gray-900 px-4 py-2 text-sm font-medium">About</Link>
          <Link to="/Services" className="block text-gray-900 px-4 py-2 text-sm font-medium">Services</Link>
          <Link to="/Contact" className="block text-gray-900 px-4 py-2 text-sm font-medium">Contact</Link>
          <Link to="/Login" className="block text-blue-600 px-4 py-2 text-sm font-medium">Login</Link>
        </div>
      </header>
      
      {/* Conditional padding to push content down when the mobile menu is open */}
      <div className={`transition-all ease-linear duration-300 ${isMobileMenuOpen ? 'pt-32' : 'pt-0'}`}>
        {/* Rest of the page content */}
      </div>
    </>
  );
};

export default Header;
