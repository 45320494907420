import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import DOMPurify from 'dompurify';

const Contact = () => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });
  const [captchaValidated, setCaptchaValidated] = useState(false);

  const navigate = useNavigate(); // Hook for navigation

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value); // Sanitize the input to prevent XSS attacks
    setFormData(prev => ({ ...prev, [name]: sanitizedValue })); // Update form data with sanitized values
  };

  const handleCaptchaChange = (value) => {
    // If value is null, that means the reCAPTCHA expired and the user must solve a new one
    setCaptchaValidated(value !== null); // Update captcha validation status based on reCAPTCHA response
    console.log("Captcha value:", value); // Debug: Log captcha value to ensure it's being received
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValidated) {
      alert('Please validate the reCAPTCHA!'); // Alert user if reCAPTCHA is not validated
      return;
    }

    // EmailJS service and template IDs, and the user ID
    const serviceID = 'service_paer8gp';
    const templateID = 'template_j9ycywh';
    const userID = '8Qsfe1AFGHPZyRyd5';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((result) => {
          console.log("EmailJS response:", result.text); // Debug: Log success response from EmailJS
          setFormData({ user_name: '', user_email: '', message: '' }); // Reset the form fields after successful submission
          navigate('/EmailSubmitted'); // Navigate to submission confirmation page
      }, (error) => {
          console.error("EmailJS error:", error.text); // Debug: Log any error response from EmailJS
          alert('Failed to send the message, please try again later.');
      });
  };

  return (
    <div className="container mx-auto p-4">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <input
          type="text"
          name="user_name"
          value={formData.user_name}
          onChange={handleChange}
          placeholder="Your Name"
          required
          className="block w-full p-2 border border-gray-300 rounded-md"
        />
        <input
          type="email"
          name="user_email"
          value={formData.user_email}
          onChange={handleChange}
          placeholder="Your Email"
          required
          className="block w-full p-2 border border-gray-300 rounded-md"
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your Message"
          required
          rows="4"
          className="block w-full p-2 border border-gray-300 rounded-md"
        ></textarea>
        <ReCAPTCHA
          sitekey="6LeGHMIpAAAAAA8eetjzNwMpZWAJHx6S-1MRa_ew" // Replace with your actual reCAPTCHA site key
          onChange={handleCaptchaChange}
          className="my-4"
        />
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Send Message
        </button>
      </form>
    </div>
  );
};

export default Contact;