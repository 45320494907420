import React from 'react';
import heroImage from '../photos/Michael_Ridgway_Main.jpeg'; // Replace with the path to your hero image

const Home = () => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-1/2 p-8">
        <h1 className="text-4xl font-bold mb-3">STRENGTHENING YOUR WEB PRESENCE WITH INTEGRATED SECURITY</h1>
        <p className="mb-6">Offering comprehensive network solutions and web development tailored to your needs.</p>
        <p className="mb-6">Explore our wide range of services and discover how we can transform your business through superior digital experiences.</p>
        <div className="flex flex-col sm:flex-row gap-4">
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">See More</button>
          <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Contact Us</button>
        </div>
      </div>
      <div className="md:w-1/2 flex justify-center items-center bg-blue-100">
        <img src={heroImage} alt="Hero" className="rounded-full" style={{ maxWidth: '90%', maxHeight: '90%' }} />
      </div>
    </div>
  );
};

export default Home;
