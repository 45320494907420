import React from 'react';
import MRImage from '../photos/Michael_Ridgway.png';
import DGImage from '../photos/Dana_Geadah.png'

const About = () => {
  return (
    <div className="container mx-auto p-4">
      <h1>About Us</h1>
      <p>We are a team dedicated to revolutionizing the way businesses interact with technology. Our mission is to empower our clients by providing cutting-edge solutions that drive innovation and efficiency.</p>
      <div className="mt-4">
        <h2>Our History</h2>
        <p>Founded in 2020, we're a growing tech startup working to provide comprehensive web development and networking solutions in our industry.</p>
        <h2>Meet the Team</h2>
        <div className="flex justify-around">
          <div>
            <img src={MRImage} alt="Michael Ridgway" className="rounded-full w-24 h-24 object-cover"/>
            <p><strong>Michael Ridgway</strong><br />Founder & CEO</p>
          </div>
          <div>
            <img src={DGImage} alt="Dana Geadah" className="rounded-full w-24 h-24 object-cover"/>
            <p><strong>Dana Geadah</strong><br />CMO</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
