import React from 'react';

const Services = () => {
  return (
    <div className="container mx-auto p-4">
      <h1>Our Services</h1>
      <div className="mt-4 grid grid-cols-3 gap-4">
        <div className="p-4 shadow rounded">
          <h2>Web Development</h2>
          <p>We build custom, scalable web solutions that empower businesses to engage with their audience effectively.</p>
        </div>
        <div className="p-4 shadow rounded">
          <h2>Networking Solutions</h2>
          <p>From home setups to SMB infrastructures, we provide robust networking solutions.</p>
        </div>
        <div className="p-4 shadow rounded">
          <h2>Cybersecurity Solutions</h2>
          <p>Protecting your business from digital threats with cutting-edge security measures.</p>
        </div>
      </div>
    </div>
  );
};

export default Services;
