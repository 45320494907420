import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Login from './components/Login';
import EmailSubmitted from './components/EmailSubmitted';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen"> {/* Ensures the outer container is a flex column and takes minimum screen height */}
        <Header />
        <main className="flex-grow mt-8 p-4"> {/* Makes main content grow to take available space, pushing the Footer down */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/emailsubmitted" element={<EmailSubmitted />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
